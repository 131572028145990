:root {
    --static: rgba(225, 225, 225, 0.5);
    --static4: rgba(225, 225, 225, 0.2);
    --static3: #ffffff;
    --static2: #0f0f0f;
    --primary: #000000;
    --secondary: #ffffff;
    --primbuttn: #4685FF;
    --secbuttn: #F2F2F2;
    --accent: #FFB084;

}

:root { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  :root { font-family: 'Inter var', sans-serif; }
}

:root {
    --h1: 48px;
    --h2: 32px;
    --subtitle: 24px;
    --p: 18px;
    --sub: 16px;
}

* {
    box-sizing: border-box;
    scroll-padding-top: 3em;
}

.hello {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 30px;
    margin: 0 15%;
    gap: 4em;

    /* font */
    font-family: 'Inter', sans-serif;

    /* color */
    color: var(--primary);
    background-color: var(--secondary);
    
}


/* text styles */


h2 {
    font-size: 32px;
    letter-spacing: -2px;
    font-weight: 700;
    margin: 0;
    font-family: 'Bookman';
}

p {
    font-size: 18px;
    letter-spacing: normal;
    line-height: 150%;
    color: #000000;
    font-family: 'Bookman';
}


/* toolbar styles */
.randomize {
    padding: 1.1em 2em;
}

.export {
    padding: 1.1em 2em;
}


/* toolbar animation after clicking Get Started */



@keyframes highlight {
    0%, 100% {transform: translate(0, 0px) scale(1); transition: all 0.4s cubic-bezier(.47,1.64,.41,.8);}

    25% {transform: translate(0, -15px) scale(1); transition: all 0.4s cubic-bezier(.47,1.64,.41,.8);}

    50% {transform: translate(0, 0px) scale(1); transition: all 0.4s cubic-bezier(.47,1.64,.41,.8);}

    75% {transform: translate(0, -7px) scale(1); transition: all 0.4s cubic-bezier(.47,1.64,.41,.8);}
}


/* part 2 */

.part2 {
    background-color: var(--accent);
    border-radius: 6px;
    padding: 3em 5em;
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    gap: 6em;
    justify-content: space-between;
}

.part2-left {
    width: 30%;
    margin-top: 20px;
}

.part2-right {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 70%;
    gap: 5em;
}

.step {
    width: 30%;
    flex-grow: 2;
    color: #000000;
    text-align: justify;
    font-family: 'Poppins';
    margin-top: 20px;
}

.one::before {
    content: '1';
    font-size: var(--h2);
    font-weight: 800;
    position: absolute;
    transform: translate(-1em, 0);
}

.two::before {
    content: '2';
    font-size: var(--h2);
    font-weight: 800;
    position: absolute;
    transform: translate(-1em, 0);
}

.three::before {
    content: '3';
    font-size: var(--h2);
    font-weight: 800;
    position: absolute;
    transform: translate(-1em, 0);
}

.four::before {
    content: '4';
    font-size: var(--h2);
    font-weight: 800;
    position: absolute;
    transform: translate(-1em, 0);
}




/* responsiveness */


@media screen and (max-width: 1200px) {
    .hello {
        margin: 0 5%;
    }

    h2 {
        font-size: 24px;
    }

    .subtitle {
        font-size: 20px;
    }
}

@media screen and (max-width: 900px) {

    .hello {
        gap: 2em;
        margin-top: 30px;
    }

    .part2 {
        flex-direction: column;

    }

    .part2-left {
        width: 100%;
    }

    .part2-right {
        width: 100%;
    }
    
}

@media screen and (max-width: 800px) {

    .step {width: 100%;}

    .part2 {
        gap: 1em;
        margin-bottom: 50px;
        margin-top: -60px;
    }

    .part2-right {
        gap: 1em;
    }


}




/* mobile expand button */

.rolloutbutton {
    display: none;
    background-color: var(--static3);
    color: var(--static2);
}

.rollout {
    display: flex;
}