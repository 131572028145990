.jobsearchimgg
{
    height: auto;
    width: 900px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 150px;
}

section{
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff; 
    }
    
    header{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    padding: 20px 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    }
    
    header .logo{
    position: relative;
    max-width: 80px;
    }
    
    header ul{
    position: relative;
    display: flex
    }
    
    header ul li{
    list-style: none;
    }
    
    header ul li a{
    display: inline-block;
    color: #333;
    font-weight: 400;
    margin-left: 40px;
    text-decoration: none;
    }
    
    .content{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-top: -100px;
    }
    
    .content .textBox{
    position: relative;
    max-width: 600px;
    }
    
    .content .textBox h2{
    color: #333;
    font-size: 4em;
    line-height: 1.4em;
    font-weight: 500;
    margin-top: 100px;
    }
    
    .content .textBox h2 span{
    color: orange;
    font-size: 1.2em;
    font-weight: 900;
    }
    
    .content .textBox p{
    color: #333;
    }
    
    .content .textBox a{
    display: inline-block;
    margin-top: 5px;
    padding: 10px 20px;
    background: orange;
    color: #fff;
    border-radius: 40px;
    font-weight: 500;
    letter-spacing: 1px;
    text-decoration: none;
    }
    // .content .imgBox{
    // width: 600px;
    // display: flex;
    // justify-content: flex-end;
    // padding-right: 50px;
    // margin-top: 50px;
    // }
    // .content .imgBox img{
    // max-width:  340px;
    // }

    .imgBox
    {
        width: 600px;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        
    }
    
    .thumb{
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translate(-50%);
    display: flex;
    }
    
    .thumb li{
    list-style: none;
    display: inline-block;
    margin: 0 20px;
    cursor: pointer;
    transition: 0.5s;
    }
    .thumb li:hover{
    transform: translateY(-15px);
    }
    .thumb li img{
    max-width: 60px;
    }
    .sci{
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    }
    
    .sci li{
    list-style: none;
    } 
    .sci li a{
    display: inline-block;
    margin: 5px 0;
    transform: scale(0.6);
    filter: invert(1);
    }
    
    .circle{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: orange;
    clip-path: circle(500px at right 900px);
    margin-top: -30px;
    }
    
    @media (max-width: 991px)
    {
    
        section{
            position: relative;
            width: 100%;
            min-height: 100vh;
            padding: 100px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #fff; 
            margin-top: -100px;
            // margin-bottom: -160px;
            }

        .circle{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: orange;
            clip-path: circle(600px at right 1200px);
            margin-top: -20px;
            }


            .jobsearchimgg
            {
                height: auto;
                width: 900px;
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-top: 20px;
            }

            .content .textBox a{
                display: inline-block;
                margin-top: 5px;
                padding: 10px 20px;
                background: orange;
                color: #fff;
                border-radius: 40px;
                font-weight: 500;
                letter-spacing: 1px;
                text-decoration: none;
                }

    header{
    padding: 20px;
    }
    
    header .logo{
    max-width: 60px;
    }
    
    header ul{
    
    display: none;
    }
    
    .toggle{
    position: relative;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background: url(https://g.top4top.io/p_2250pmpfo1.png);
    background-size: 30px;
    background-position: center;
    background-repeat: no-repeat;
    filter: invert(1);
    z-index: 11;
    
    
    }
    .toggle.active{
    position: fixed;
    right: 20px;
    background: url(https://a.top4top.io/p_2250quwof1.png);
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
    
    }
    
    header ul.navigation.active{
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fff;
    z-index: 10;
    }
    
    header ul li a{
    font-size: 1.5em;
    margin: 5px 0;
    }
    section{
    padding: 20px 20px 120px;
    }
    
    .content{
    flex-direction: column;
    margin-top: 100px;
    }
    
    .content .textBox{
    max-width: 100%;
    }
    
    .content .textBox h2{
    font-size: 2.5em;
    margin-bottom: 15px;
    }
    
    .content .imgBox{
    max-width: 100%;
    justify-content: center !important;
    }
    
    .content .imgBox img{
    max-width: 300px;
    
    }
    
    .thumb li img{
    max-width: 40px;
    }
    
    // .circle{
    // clip-path: circle(400px at center bottom);
    
    // }
    
    .sci{
    background-color:#017143;
    right: 0;
    width: 50px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    }
    }

    