.InfoRecruiterGridDiv
{
   margin-top: -80px;
   margin-bottom: 40px;
}

.InfoRecruiterGridHeadLine
{
	color: #333;
    font-size: 3em;
    line-height: 1.4em;
    font-weight: 500;
    margin-left: 20px;
    margin-right: 20px;
	font-family: 'Poppins';
}

.InfoRecruiterGridSpan
{
    color: orange;
    font-size: 1.2em;
    font-weight: 900;
}

.InfoRecruiterGridParagraph
{
    margin-left: 20px;
    margin-right: 20px;
}

.InfoRecruiterGridbutton
{
    margin-left:20px;
    margin-right: 50px;
    background-color:#4838c0; 
    border: none;
    color: white;
    padding: 10px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    margin-top: 5px;
    border-radius: 12px;
}

.InfoRecruiterGridbutton:hover 
{
    box-shadow: 0 12px 16px 0 
    rgba(0,0,0,0.24),0 17px 50px 0
    rgba(0,0,0,0.19);
} 

.HiringImageee
{
    width: 500px;
    height: auto;
    display: block;
    margin-top: -200px;
    margin-left: auto;
    margin-right: auto;
}


@media (max-width: 991px)
{

    .InfoRecruiterGridHeadLine
    {
        color: #333;
        font-size: 2em;
        line-height: 1.4em;
        font-weight: 500;
        margin-left: 20px;
        margin-right: 20px;
        font-family: 'Bookman';
        margin-top: 120px;
    }
    
    .InfoRecruiterGridSpan
    {
        color: orange;
        font-size: 1.2em;
        font-weight: 900;
    }
    
    .InfoRecruiterGridParagraph
    {
        margin-left: 20px;
        margin-right: 20px;
        text-align: justify;
    }
    
    .InfoRecruiterGridbutton
    {
        margin-left:20px;
        margin-right: 50px;
        background-color:#4838c0; 
        border: none;
        color: white;
        padding: 10px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        cursor: pointer;
        -webkit-transition-duration: 0.4s;
        transition-duration: 0.4s;
        margin-top: 5px;
        border-radius: 12px;
    }
    
    .InfoRecruiterGridbutton:hover 
    {
        box-shadow: 0 12px 16px 0 
        rgba(0,0,0,0.24),0 17px 50px 0
        rgba(0,0,0,0.19);
    } 
    
    .HiringImageee
    {
        width: 60%;
        height: auto;
        display: block;
        margin-top: -90px;
        margin-left: auto;
        margin-right: auto;
    }
    
}
