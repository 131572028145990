/* .Homeclass
{
  text-align: center;
  margin-left: 350px;
}

.Jobsclass
{
  margin-left: 20px;
}

.Blogsclass
{
  margin-left: 20px;
}

.SignButton
{
  margin-right: 40px;
}

.LogButton
{
  margin-right: 30px;
}

.logoclass
{
  height: 50px;
  width: auto;
  display: block;
}

.btn-primary {
  color: #fff;
  background-color: orange;
  border-color: orange;
}

@media screen and (max-width: 981px)
{
  .Homeclass
  {
    margin-left: 0px;
  }

  .Jobsclass
  {
    margin-left: 0px;
  }

  .Blogsclass
  {
    margin-left: 0px;
  }
} */


.logoclass
{
  height: 50px;
  width: auto;
  display: block;
}

.nav-item
{
  margin: 10px;
}

.buttonn
{
  margin: 10px;
    background-color:orange; 
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    margin-top: 5px;
    border-radius: 16px;
    font-weight: 500;
}

.buttonn:hover {
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}

.buttonnn
{
  margin: 10px;
  background-color:white; 
  border: none;
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  margin-top: 5px;
  border-radius: 16px;
  font-weight: 500;
}

.nav-link
{
  font-weight: 500;
}

.buttonnn:hover {
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}

.navbar {
  background: #fff;
  box-shadow: 0 4px 6px rgba(22, 22, 26, 0.18);
}



.navbar .navbar-nav .nav-link {
  color: #000;
}
.btn-outline-dark {
  border-radius: 0;
}
.btn-outline-dark:focus {
  box-shadow: none;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow .15s ease-in-out;
  border: none;
}

.navbar-collapse
{
  color: white;
}


.popup-flex{
  margin:30px;
}
.popup-container {
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    transform: scale(1.3);
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(21, 17, 17, 0.61);
    display: flex;
    align-items: center;
}
.popup-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 50%;
}
.popup-content p{
    font-size: 17px;
    padding: 10px;
    line-height: 20px;
}
.popup-content span{
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.popup-content span:hover,
.popup-content span:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.show{
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.more-articles{
  margin:30px 30px 50px;
  font-size:20px;
}
.more-articles a{
  margin:0 10px;
  text-decoration:underline;
}




/* modal */
.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.show {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-dialog {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  width: 100%;
  margin: 0;
}

.modal-title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
  font-family: 'Bookman';
}

/* .modal-body {
  margin-bottom: 20px;
} */

.modal-backdrop.fade.show {
  display: none;
}

.btn-close {
  padding: 0.5rem 0.75rem;
  margin: 0;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  opacity: 0.5;
  cursor: pointer;
}

.btn-close:hover {
  opacity: 1;
}

.fade.modal.show {
  display: flex;
}

.custom-modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  /* background: linear-gradient(to top, #3f2b96, #a8c0ff); */
  border-radius: 0.3rem;
  outline: 0;
}

.modal.show .modal-dialog {
  transform: none;
  background: rgb(244, 246, 249);
}



/* image */
.imagee {
  width: 100%;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to top, #f09819, #FF512F);
  margin-bottom: 20px;
  border-radius: 0.3rem;
}

.imagee img {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  border: 10px solid rgb(241, 145, 10);
  margin-bottom: 10px;
}

.imagee2
{
  width: 100%;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to top, #1565c0, #753a88);
  margin-bottom: 20px;
  border-radius: 0.3rem;
}

.imagee2 img{
  height: 150px;
  width: 150px;
  border-radius: 50%;
  border: 10px solid slateblue;
  margin-bottom: 10px;
}

.imagee-label {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  color: #000;
  font-family: 'Poppins';
}

@media screen and (max-width: 901px) {
  
  .modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.show {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-dialog {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  width: 100%;
  margin: 0;
}

.modal-title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
  font-family: 'Bookman';
}

/* .modal-body {
  margin-bottom: 20px;
} */

.modal-backdrop.fade.show {
  display: none;
}

.btn-close {
  padding: 0.5rem 0.75rem;
  margin: 0;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  opacity: 0.5;
  cursor: pointer;
}

.btn-close:hover {
  opacity: 1;
}

.fade.modal.show {
  display: flex;
}

.custom-modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 0.3rem;
  outline: 0;
}


  .btn-outline-dark {
    border: none;
    padding: 0;
    outline: none;
  }

  .btn-outline-dark:hover {
    background: none;
    color: black;
  }

  .modal.show .modal-dialog {
    transform: none;
    background: rgb(244, 246, 249);
  }

  /* image */
.imagee {
  width: 100%;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to top, #f09819, #FF512F);
  margin-bottom: 20px;
  border-radius: 0.3rem;
}

.imagee img {
  height: 130px;
  width: 130px;
  border-radius: 50%;
  border: 10px solid rgb(241, 145, 10);
  margin-bottom: 10px;
}

.imagee2
{
  width: 100%;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to top, #1565c0, #753a88);
  margin-bottom: 20px;
  border-radius: 0.3rem;
}

.imagee2 img{
  height: 130px;
  width: 130px;
  border-radius: 50%;
  border: 10px solid slateblue;
  margin-bottom: 10px;
}

.imagee-label {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  color: #000;
  font-family: 'Poppins';
}

.modal-dialog {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  width: 95%;
  margin: 0;
}

/* .css-o7llqo-MuiGrid-root {
  box-sizing: border-box;
  display: contents;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.css-csw05u-MuiGrid-root {
  box-sizing: border-box;
  display: contents;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.css-1d3bbye {
  box-sizing: border-box;
  flex-flow: row wrap;
  width: 100%;
}

.css-2crnm1 {
  box-sizing: border-box;
  display: contents;
  flex-direction: row;
}

.css-wssjmc {
  box-sizing: border-box;
  display: contents;
  flex-direction: row;
} */


}
