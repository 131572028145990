@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

*, *:after, *:before {
	box-sizing: border-box;
}

img {
	max-width: 100%;
	display: block;
}

input {
	appearance: none;
	border-radius: 0;
}

.card {
	margin: 2rem auto;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 425px;
	background-color: #FFF;
	border-radius: 10px;
	box-shadow: 0 10px 20px 0 rgba(#999, .25);
	padding: .75rem;
	margin-top: -10px;
}

.card-image {
	border-radius: 8px;
	overflow: hidden;
	// padding-bottom: 60%;
	// background-image: url('https://img.freepik.com/free-photo/3d-rendering-people-avatars-zoom-call_23-2149576734.jpg?w=996&t=st=1684585722~exp=1684586322~hmac=a96d16f3812ea2ecc1df2312b157680928f032aa7b5da56b2258d5e6d9d13a63');
	// background-repeat: no-repeat;
	// background-size: 100%;
	background-position: 0 32%;
	position: relative;
}

.card-heading {
	position: absolute;
	left: 10%;
	top: 5%;
	right: 10%;
	font-size: 1.75rem;
	font-weight: 700;
	color: black;
	line-height: 1.222;
	small {
		display: block;
		font-size: .75em;
		font-weight: 400;
		margin-top: .25em;
	}
}

.card-form {
	padding: 2rem 1rem 0;
    margin-top: -30px;
}

.input {
	display: flex;
	flex-direction: column-reverse;
	position: relative;
	padding-top: 1.5rem;
	&+.input {
		margin-top: 15px;
	}
}

.input-label {
	color: #8597a3;
	position: absolute;
	top: 1.5rem;
	transition: .25s ease;
}

.input-field {
	border: 0;
	z-index: 1;
	background-color: transparent;
	border-bottom: 2px solid #eee; 
	font: inherit;
	font-size: 1.125rem;
	padding: .25rem 0;
	&:focus, &:valid {
		outline: 0;
		border-bottom-color: #6658d3;
		&+.input-label {
			color: #6658d3;
			transform: translateY(-1.5rem);
		}
	}
}

.action {
	margin-top: 2rem;
}

.action-button {
	font: inherit;
	padding: 1em;
	width: 100%;
	font-weight: 500;
	background-color: #6658d3;
	border-radius: 6px;
	color: #FFF;
    margin-top: -20px;
	border: 0;
	&:focus {
		outline: 0;
	}
}

.card-info {
	padding: 1rem 1rem;
	text-align: center;
	font-size: .875rem;
	color: #8597a3;
	a {
		display: block;
		color: #6658d3;
		text-decoration: none;
	}
}

.Recruitersection{
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff; 
    }


.Recruiterdiv
{
	margin-top: 140px;
}

.RecruiterHeadline
{
	color: #333;
    font-size: 4em;
    line-height: 1.4em;
    font-weight: 500;
	font-family: 'Poppins';
}

.RecruiterHeadlinespan
{
	color: orange;
    font-size: 1.2em;
    font-weight: 900;
}

.Recruiterparagraph
{
	margin-left: 40px;
	margin-right: 40px;
}

.RecruiterGridDiv
{
	margin-top: 100px;
}

@media (max-width: 991px)
{
	.RecruiterHeadline
	{
		color: #333;
		font-size: 2em;
		line-height: 1.4em;
		margin-left: 20px;
		margin-right: 20px;
		font-family: 'Poppins';
	}

	.RecruiterGridDiv
    {
	margin-top: -10px;
    }
}

  
  .page
  {
	display: flex;
	align-items: center;
	justify-content: center;
  }
  
  .button
  {
	appearance: none;
	outline: none;
	border: none;
	display: inline-block;
	background-color: black;
	color: #fff;
	font-size: 20px;
	padding: 10px 15px;
	border-radius: 8px;
	box-shadow: 0 3px 6px rgba(0,0,0,0.1);
	cursor: pointer;
	margin: 0 15px;
  }
  
  .button.large 
  {
	font-size: 24px;
	padding: 15px 30px;
  }
  
  .popup 
  {
	position: fixed;
	display: flex;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	align-items: center;
	justify-content: center;
	z-index: 999;
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.4s;
  }
  
  .popup.is-active 
  {
	opacity: 1;
	pointer-events: all;
  }
  
  .popup-overlay 
  {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
	background-color: rgba(0,0,0,0.5);
  }
  
  .popup-inner 
  {
	position: relative;
	z-index: 1;
	max-width: 600px;
	padding: 50px;
	background-color: #fff;
	border-radius: 16px;
	box-shadow: 0 3px 6px rgba(0,0,0,0.1);
  }
  
  .popup h2 
  {
	font-size: 28px;
	color: #313131;
	font-weight: 600;
  }
  
  .popup h3 
  {
	color: #888;
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 30px;
  }
  
  .popup p
  {
	color: #666;
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 15px;
  }
  .popup p:last-of-type
  {
	margin-bottom: 30px;
  }


  @import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.OTPcontainer
{
  border-radius: 12px;
  background-color: rgb(19, 25, 28);
  width: 90%;
  max-width: 500px;
  padding: 50px 20px;
  text-align: center;
}
.OTPtitle {
  font-size: 25px;
  margin-bottom: 30px;
  color: white;
}
#otp-form {
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
#otp-form input {
  border: none;
  background-color: #121517;
  color: white;
  font-size: 32px;
  text-align: center;
  padding: 10px;
  width: 100%;
  max-width: 120px;
  height: 50px;
  border-radius: 4px;
  outline: 2px solid rgb(66, 66, 66);
}
#otp-form input:focus-visible {
  outline: 2px solid royalblue;
}
#otp-form input.filled {
  outline: 2px solid rgb(7, 192, 99);
}
#verify-btn {
  cursor: pointer;
  display: inline-block;
  background: royalblue;
  color: white;
  padding: 13px 20px;
  border-radius: 4px;
  font-size: 16px;
  border: none;
}


@media (max-width: 991px)
{
    #otp-form input {
        border: none;
        background-color: #121517;
        color: white;
        font-size: 32px;
        text-align: center;
        padding: 10px;
        width: 100%;
        max-width: 120px;
        height: 50px;
        border-radius: 4px;
        outline: 2px solid rgb(66, 66, 66);
      }

    .OTPcontainer {
        border-radius: 12px;
        background-color: rgb(19, 25, 28);
        width: 90%;
        max-width: 500px;
        padding: 50px 20px;
        text-align: center;
        /* margin-top: 150px; */
        margin-left: 15px;
      }
}
